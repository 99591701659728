<template>
  <div>
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>
  
    <b-card title="Quality Audit">
  
      <b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1" >
          <div class="alert-body">{{error_message}}</div>
      </b-alert>
  
      <b-row class="form_detail">
        <b-col md="3">
            <b-form-group label="Project Site" class="required">
              <b-form-select v-model="site" @change="siteChange" :disabled="id != null">
                <b-form-select-option value="" disabled>Select</b-form-select-option>
                <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name | capitalize}}</b-form-select-option>
              </b-form-select>
            </b-form-group>
        </b-col>
  
        <b-col md="3" >
            <b-form-group label="Location" class="required">
              <b-form-select v-model="location_id" @change="getItemData()" :disabled="id != null">
                <b-form-select-option value="" disabled>Select</b-form-select-option>
                <b-form-select-option :value="l._id" v-for="l in allLocations" :key="l._id">{{l.name | capitalize}}</b-form-select-option>
              </b-form-select>
            </b-form-group>
        </b-col>
  
        <b-col md="3">
            <b-form-group label="Inspected By" class="required">
                <b-form-input placeholder="3 to 50 characters" v-model="supervisor_name" :disabled="id != null" autocomplete="off" />
            </b-form-group>
        </b-col>

        <b-col md="3">
            <b-form-group label="Inspection Date Time" class="mobile_tab_margin_bottom_zero">
                <date-picker v-model="checked_date_time" 
                  format="DD MMM YYYY HH:mm" 
                  type="datetime"
                  placeholder="Select"
                  valueType="format"
                  :disabled-date="disabledDate"
                  :disabled-time="disabledTime"
                  :clearable=false
                  :confirm=true
                  lang="en"
                  :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
                  :disabled="id != null"
                ></date-picker>
            </b-form-group>
        </b-col>

      </b-row>

    </b-card>
  
    <div v-if="items != null">
      <!-- <b-card> -->
        <b-form @submit="formSubmit">

          
          <!-- <b-card> -->
            <div class="mb-2" v-for="(c,index) in items" :key="index">

              <div class="table-responsive rounded" style="margin-bottom: 0px !important;">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col" style="padding-top: 22px; width: 7%;">{{String.fromCharCode(65+index)}}</th>
                            <th scope="col" style="padding-top: 22px; width: 40%;">{{c.unit_name}}</th>
                            <th scope="col" style="padding-top: 22px; width: 16%;">Pass</th>
                            <th scope="col" style="padding-top: 22px; width: 16%;">Fail</th>
                            <th scope="col" style="padding-top: 22px; width: 16%;">N/A</th>
                            <th scope="col" style="width: 5%">
                              <b-button variant="warning" class="mt-0 ml-50 mr-0 float-left" @click="addElement(index)" size="sm" >
                                <feather-icon icon="PlusIcon" />
                              </b-button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(e,i) in c.elements" :key="i+100">
                            <td>{{i + 1}}</td>
                            <td>
                                <p v-if="typeof e.temp == 'undefined'"> {{e.name}} </p>
                      
                                <b-form-input v-if="typeof e.temp != 'undefined'" v-model="e.name" class="form-control" type="text" />
                            </td>
                            <td>
                                <b-form-checkbox value="yes" class="custom-control-warning" @change="updateCheckbox($event,index,i,'pass')" v-model="e.pass" />
                            </td>
                            <td>
                                <b-form-checkbox value="yes" class="custom-control-danger" v-model="e.fail" @change="updateCheckbox($event,index,i,'fail')" />
                            </td>
                            <td>
                                <b-form-checkbox value="yes" class="custom-control-secondary" v-model="e.na" @change="updateCheckbox($event,index,i,'na')" />
                            </td>
                            <td class="text-center">
                                <b-button variant="danger" class="mt-0 ml-50 mr-0 float-left" @click="removeElement(index,i)" size="sm" v-if="typeof e.temp != 'undefined'" >
                                    <feather-icon icon="XIcon" />
                                </b-button>
                            </td>
                        </tr>
                    </tbody>
                </table>
              </div>

            </div>
          <!-- </b-card> -->

          <!-- <b-card v-if= "ga.length > 0"> -->
            <div v-if= "ga.length > 0" class="table-responsive rounded" style="margin-bottom: 0px !important;">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col" style="padding-top: 22px; width: 63%;">General Awareness</th>
                            <th scope="col" style="padding-top: 22px; width: 16%;">Yes</th>
                            <th scope="col" style="padding-top: 22px; width: 16%;">No</th>
                            <th scope="col" style="width: 5%">
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(g,i) in ga" :key="i">
                            <td>{{g.name}}</td>
                            <td>
                                <b-form-checkbox value="yes" class="custom-control-warning" @change="updateGACheckbox($event,i,'pass')" v-model="g.pass" />
                            </td>
                            <td>
                              <b-form-checkbox value="yes" class="custom-control-danger" v-model="g.fail" @change="updateGACheckbox($event,i,'fail')" />
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
              </div>
          <!-- </b-card> -->

          
          <b-card class="mt-2">
            
            <b-row>
                <b-col md="12">
                  <b-form-group label="Additional Comment" >
                      <b-form-textarea v-model="comment" placeholder="upto 120 characters" rows="4" />
                  </b-form-group>
                </b-col>
            </b-row>
  
            <b-row>
              <b-col cols="12" class="mb-2 mt-2" > 
  
                <b-col class="mb-2 mobile_pdng_lft_rgt_zero">
                  <center>
                    <h4 class="mb-0 align_start_mobile"> Signature </h4>
                  </center>
                </b-col>
             
                <b-col class="mb-2 mt-0 mobile_pdng_lft_rgt_zero" style="text-align: center" > 
              
                    <b-media class="mb-2 mt-2 desktop_display_block" @click="openSignature('supervisor_signature')" v-if="supervisorSign.image == null">
                      <template #aside>
                        <b-avatar
                          ref="supervisorS"
                          :src="doc_icon"
                          variant="light-danger"
                          size="200px"
                          rounded
                        />
                      </template>
  
                      <div class="d-flex flex-wrap">
                        <input
                          ref="supervisorSInput"
                          type="file"
                          class="d-none"
                          @input="supervisorImageUpload"
                        >
                      </div>
  
                      
                    </b-media>
  
                    <img :src="supervisorSign.image" width="200px" height="180" v-if="supervisorSign.image != null" style="background: #ffffff">
  
                    
                    <center class="single_img_danger_btn">
                      <b-button
                          variant="danger"
                          class="mt-1 ml-2"
                          v-if="supervisorSign.image != null"
                          @click="removeSign('supervisor')"
                          :disabled="disabledOptions"
                        >
                        <feather-icon icon="XIcon" />
                        
                      </b-button>
                    </center>
                  
                  </b-col>
              
              </b-col>
            </b-row>
    
            <b-row class="mt-1">
              <b-col>
  
                <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" class = "mr-1" variant="outline-secondary" @click="goBack()" >
                  Cancel
                </b-button>
  
                <b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" variant = "danger" class = "mr-1" @click="discardItem(id)" v-if="report_status == 'draft'">
                  Discard Draft
                </b-button>  
  
                <b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" variant = "primary" class = "mr-1" type="submit" @click="status = 'draft'" v-if="typeof $route.params.id == 'undefined' || report_status == 'draft'">
                    {{ id == null ? 'Save as Draft' : 'Update Draft'}}
                </b-button>   
  
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="warning" class="mr-1" type="submit" @click="status = 'completed'" >
                  {{ typeof $route.params.id == 'undefined' ? 'Submit' : 'Save' }}
                </b-button>
                
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-1"
                  v-if="typeof $route.params.id != 'undefined' && ledit == false && report_status != 'draft'"
                  @click="openSignature('approver')"
                >
                  {{ typeof $route.params.id == 'undefined' ? 'Submit' : 'Approve' }}
                </b-button>
  
              </b-col>
            </b-row>
            
          </b-card>
  
        </b-form>
      <!-- </b-card> -->
  
      <b-modal
          id="signatureModel"
          ref="signatureModel"
          centered
          title="Signature"
          no-close-on-backdrop
          hide-footer
          @hide="hideSignature"
          @show="hideSignature"
        >
        
        <div v-if="show_default == true">
          <b-row>
  
            <b-col md="11" style="background: #ffffff; height:300px; vertical-align: middle;margin-left: 4%;margin-right:2%">
              <center>
                <img :src="tempSign.image" width="240px" height="200px" style="background: #ffffff; border-radius: 4px;margin-top: 11%">
              </center>
  
            </b-col>
          </b-row>
          <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined' && report_status != 'draft'">
            
            <b-col md="12">
              <date-picker v-model="approve_signature_time" 
                format="DD MMM YYYY HH:mm" 
                type="datetime"
                placeholder="Select"
                valueType="format"
                :clearable=false
                :confirm=true
                lang="en"
                :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
                :disabled="$store.getters.currentUser.role == 'supervisor' || $store.getters.currentUser.role == 'operation_manager' || $store.getters.currentUser.role == 'admin' ? false : true"
                :disabled-date="disabledDate"
                :disabled-time="disabledTime"
                ></date-picker>
            </b-col>
          </b-row>
          <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined' && report_status != 'draft'">
            
            <b-col md="12">
              <b-form-group
                  label="Remarks"
                  class=""
                >
                  <b-form-textarea
                    placeholder=""
                    rows="3"
                    v-model="approve_remark"
                  />
                </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-2">
  
            <b-col md="6">
  
                <b-button
                    variant="secondary"
                    class="mt-0 w-100"
                    @click="show_default = false"
                  >
                  <span class="text-nowrap">Add New Signature</span>
                </b-button>
              </b-col>
  
              <b-col md="6">
                <b-button
                    variant="warning"
                    class="mt-0 float-right w-100"
                    @click="saveDefault()"
                  >
                  <span class="text-nowrap">Submit</span>
                </b-button>
              </b-col>
          </b-row>
        </div>
  
        <div v-else>
          <b-row>
            <b-col md="12">
              <VueSignaturePad
                id="signature"
                width="100%"
                height="300px"
                ref="signaturePad"
                :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()},penColor: '#000000',minWidth: 3,maxWidth: 3}"
              />
  
            </b-col>
          </b-row>
          <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined' && report_status != 'draft'">
            
            <b-col md="12">
              <date-picker v-model="approve_signature_time" 
                format="DD MMM YYYY HH:mm" 
                type="datetime"
                placeholder="Select"
                valueType="format"
                :clearable=false
                :confirm=true
                lang="en"
                :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
                :disabled="$store.getters.currentUser.role == 'supervisor' || $store.getters.currentUser.role == 'operation_manager' || $store.getters.currentUser.role == 'admin' ? false : true"
                :disabled-date="disabledDate"
                :disabled-time="disabledTime"
                ></date-picker>
  
  
            </b-col>
          </b-row>
  
          <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined' && report_status != 'draft'">
            
            <b-col md="12">
              <b-form-group
                  label="Remarks"
                  class=""
                >
                  <b-form-textarea
                    placeholder=""
                    rows="3"
                    v-model="approve_remark"
                  />
                </b-form-group>
            </b-col>
          </b-row>
          
          <b-row class="mt-2">
  
            <b-col md="3" v-if="typeof $route.params.id == 'undefined'">
              <b-button
                  variant="primary"
                  class="mt-0 w-100"
                  @click="uploadSign()"
                >
                <span class="text-nowrap">Upload</span>
              </b-button>
            </b-col>
  
            <b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">
  
                <b-button
                    variant="secondary"
                    class="mt-0 w-100"
                    v-if="having_default == true"
                    @click="show_default = true"
                  >
                  <span class="text-nowrap">Default</span>
                </b-button>
              </b-col>
  
  
            <b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">
  
                <b-button
                    variant="danger"
                    class="mt-0 w-100"
                    @click="clearSignature"
                  >
                  <span class="text-nowrap">Clear</span>
                </b-button>
              </b-col>
  
              <b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">
                <b-button
                    variant="warning"
                    class="mt-0 w-100"
                    @click="saveSignature"
                  >
                  <span class="text-nowrap">Submit</span>
                </b-button>
              </b-col>
          </b-row>
        </div>
        </b-modal>
  
    </div>
  
    
  </div>
  </template>
  
  <script>
  
  import {
    BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BAlert, BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend,
    BInputGroup, BMedia, BAvatar, BTable, BModal, BFormRadio, BFormTimepicker, BBreadcrumb, BFormTextarea, BFormDatepicker
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  import { GET_API, POST_API } from "../../../store/actions.type"
  import VueCropper from 'vue-cropperjs';
  import 'cropperjs/dist/cropper.css';
  import VueTimepicker from 'vue2-timepicker'
  import 'vue2-timepicker/dist/VueTimepicker.css'
  var moment = require('moment-timezone');
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import Datepicker from 'vuejs-datepicker';
  import flatPickr from 'vue-flatpickr-component'
  
  export default {
    components: {
      BAlert, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia, 
      BAvatar, BTable, BModal, VueCropper, BFormRadio, BFormTimepicker, VueTimepicker, BBreadcrumb, BFormTextarea, Datepicker, flatPickr, BFormDatepicker, DatePicker
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        checked_date_time: moment().format('DD MMM YYYY HH:mm'),
        id: null,
        location_id:'',
        allLocations:[],
        supervisor_name:this.$store.getters.currentUser.full_name,
        site:'',
        sites:[],
        item:null,
        error_message:'',
        doc_icon: require('@/assets/images/doc.png'),
        showDismissibleAlert:false,
        tempSign : {
          image:null,
          name:'',
        },
        show_default:false,
        having_default:false,
        supervisorSign : {
          image:null,
          name:'',
          type: 'supervisor',
          default:'no'
        },
        openedSignModel:null,
        
        disabledOptions:false,
        approve_signature_time:null,
        approve_remark:'',
        ledit : false,
        
        status: 'draft',
        report_status:null,
        
        // qa vars 
        items:null,
        form:null,
        ga:[],
        comment:''
      }
    },
    methods : {
        updateCheckbox(e,index,i,type){
        if (e) {
          if (type == 'pass') {

            this.items[index].elements[i].fail = '';
            this.items[index].elements[i].na = ''

          }else if (type == 'fail') {
            this.items[index].elements[i].pass = '';
            this.items[index].elements[i].na = ''
          }else{
            this.items[index].elements[i].fail = '';
            this.items[index].elements[i].pass = ''
          }
        }else{
          this.items[index].elements[i].pass = '';
          this.items[index].elements[i].fail = '';
          this.items[index].elements[i].na = ''
        }

        var j = 0;
          
        for (var i = 0; i < this.items[index].elements.length; i++) {

          if (this.items[index].elements[i].pass == 'yes') {
            j++
          }else if (this.items[index].elements[i].fail == 'yes') {
            j++
          }else if (this.items[index].elements[i].na == 'yes') {
            j++
          }
        }

        if (j > 0) {
          this.items[index].checked = 'yes';
        }else{
          this.items[index].checked = 'no';
        }
      },
      updateGACheckbox(e,index,type){
        if (e) {

          if (type == 'pass') {

            this.ga[index].fail = '';
          }else {
            this.ga[index].pass = '';
          }

        }else{
          this.ga[index].fail = '';
          this.ga[index].pass = '';
        }
      },
      addElement(index){
        var obj = {
          temp:'true',
          name : "",
          pass:"yes",
          fail:"",
          na:""
        }
        this.items[index].elements.push(obj);
      },
      removeElement(index,k){
        this.items[index].elements.splice(k,1);

        var j = 0;
          
        for (var i = 0; i < this.items[index].elements.length; i++) {

          if (this.items[index].elements[i].pass == 'yes') {
            j++
          }else if (this.items[index].elements[i].fail == 'yes') {
            j++
          }else if (this.items[index].elements[i].na == 'yes') {
            j++
          }
        }

        if (j > 0) {
          this.items[index].checked = 'yes';
        }else{
          this.items[index].checked = 'no';
        }
      },
      disabledDate(date){
        var today = moment().tz('Asia/Singapore');
        return date >= today;
      },
      disabledTime(date, currentValue){
        return date >= moment().tz('Asia/Singapore');
      },
  
      disabledDate2(date){
        return date < moment(new Date(this.date)).subtract(1,'days').tz('Asia/Singapore');
      },
      
      breadCrumb(){
        var item = [{
          to:{name:'client-dashboard'},
          text: 'Dashboard',
        },{
          to:null,
          text: 'Quality Management'
        },{
          to:null,
          text: 'Overview'
        },{
          to:{name:'qm-qa'},
          text: 'Quality Audit'
        },{
          to:null,
          text: 'Add Quality Audit'
        }];
        return item;
      },
  
      allSites(){
        return this.$store.dispatch(POST_API, {
             data:{
               role:this.$store.getters.currentUser.role,
             },
             api: '/api/all-sites'
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showDismissibleAlert = true;
                  window.scrollTo(0,0);
              } else {
                  this.showDismissibleAlert = false;
                  var data  = this.$store.getters.getResults.data;
                  
                  this.sites = data;
  
                  if (this.sites.length == 1) {
                              
                      this.site = this.sites[0]._id;
                      this.siteChange();
                  }
  
                  return this.sites;
              }
          });
      },
  
      siteChange(){
        
        this.site != '' ? localStorage.setItem('routeCFCheck','yes') : localStorage.setItem('routeCFCheck','no');
        this.location_id = '';
        this.allLocations = [];
        this.item = null;
        
  
        if (this.site != '') {
  
          var selectedSite = this.sites.find(item => { return item._id == this.site});
          this.no_photo_reporting = selectedSite.no_photo_reporting;
          this.allLocationsList();
        }
      },

      allLocationsList(){
        return this.$store.dispatch(POST_API, {
             data:{
               site:this.site,
               type:'QI'
             },
             api: '/api/qm-all-locations'
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showDismissibleAlert = true;
              } else {
                  
                  this.allLocations = this.$store.getters.getResults.data;
                  return this.allLocations;
              }
          });
      },

      getItemData(){
        return this.$store.dispatch(POST_API, {
             data:{
               location_id:this.location_id != '' ? this.location_id : null,
               site:this.site,
               id:this.id
             },
             api: '/api/location-detail-qm-qa'
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showDismissibleAlert = true;
              } else {
                var data = this.$store.getters.getResults.data;

                if(data.with_data){
                  this.inspected_by      = data.inspected_by;
                  this.checked_date_time = moment(data.inspection_date_time).format('DD MMM YYYY HH:mm');
                  this.items             = data.checklists;
                  this.comment           = data.comment;
                  this.ga                = data.ga;

                  var role = this.$store.getters.currentUser.role;

                  if( this.$route.params.id != undefined ){
                    this.site = data.site

                    var selectedSite = this.sites.find(item => { return item._id == this.site});

                    this.no_photo_reporting = selectedSite.no_photo_reporting;

                    this.allLocationsList().then(() => {
                      this.location_id = data.location
                    })
                  }
                  
                  if(data.supervisor_signature){
                    this.supervisorSign.image = data.supervisor_signature
                  }

                  if (data.status == 'draft') {
                    this.disabledOptions = false;
                    this.report_status = 'draft';
                  }else{
                    if(role =='admin'){
                      this.ledit = false;
                      this.disabledOptions = false;
                    } else if ((data.level_one_user_role != role) && (data.level_two_user_role != role) && (data.level_three_user_role != role)) {
                      this.ledit = true;
                      this.disabledOptions = false;
                    }else{
                      this.disabledOptions = true;
                    }
                  }

                } else {
                  this.items = data.items;
                  this.ga = data.ga;

                  if (data.id != null) {
                    this.id = data.id;
                  }

                  if (data.supervisor_name != null) {
                    this.supervisor_name = data.supervisor_name;
                  }

                  if (data.signature != null) {
                      this.supervisorSign = {
                        image:data.signature,
                        name:'',
                        type: 'supervisor',
                        default:'yes'
                      };
                  }

                  if (data.comment != "") {
                    this.comment = data.comment;
                  }

                  return this.items;
                }
  
              }
          });
      },

      updateCheckbox(e,index,i,type){
        if (e) {
          if (type == 'pass') {

            this.items[index].elements[i].fail = '';
            this.items[index].elements[i].na = ''

          }else if (type == 'fail') {
            this.items[index].elements[i].pass = '';
            this.items[index].elements[i].na = ''
          }else{
            this.items[index].elements[i].fail = '';
            this.items[index].elements[i].pass = ''
          }
        }else{
          this.items[index].elements[i].pass = '';
          this.items[index].elements[i].fail = '';
          this.items[index].elements[i].na = ''
        }

        var j = 0;
          
        for (var i = 0; i < this.items[index].elements.length; i++) {

          if (this.items[index].elements[i].pass == 'yes') {
            j++
          }else if (this.items[index].elements[i].fail == 'yes') {
            j++
          }else if (this.items[index].elements[i].na == 'yes') {
            j++
          }
        }

        if (j > 0) {
          this.items[index].checked = 'yes';
        }else{
          this.items[index].checked = 'no';
        }
      },
      
      updateGACheckbox(e,index,type){
        if (e) {

          if (type == 'pass') {

            this.ga[index].fail = '';
          }else {
            this.ga[index].pass = '';
          }

        }else{
          this.ga[index].fail = '';
          this.ga[index].pass = '';
        }
      },
      
      
      useDefault(){
  
        return this.$store.dispatch(POST_API, {
             data:{
               id:this.$store.getters.currentUser._id
             },
             api: '/api/get-default-signature'
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showDismissibleAlert = true;
                  window.scrollTo(0,0);
              } else {
                  this.showDismissibleAlert = false;
                  var data  = this.$store.getters.getResults.data;
                  
                  if (data == null || data.default_signature == null) {
                    
                    this.having_default = false;
                    
                  }else{
  
                    this.tempSign = {
                      image : data.default_signature,
                      name : 'signature.png',
                    }
  
                    this.supervisorSign = {
                      image:data.default_signature,
                      name:'signature.png',
                      type: 'supervisor',
                      default:'yes'
                    };
  
                    this.having_default = true;
                  }
              }
          });
      },
      uploadSign(){
        
        if (this.openedSignModel == 'supervisor_signature') {
  
           this.$refs['supervisorSInput'].click();
        }
  
        /*if (this.openedSignModel == 'inspected_by') {
  
           this.$refs['inspectedSInput'].click();
        }*/
  
        this.$refs['signatureModel'].hide();
  
      },
      openSignature(type){
        this.approve_signature_time = moment(new Date()).tz('Asia/Singapore').format('DD MMM YYYY HH:mm');
        
        if (this.openedSignModel == null) {
  
            if (this.having_default == true) {
                this.show_default = true;
            }else{
              this.show_default = false;
            }
  
            this.$refs['signatureModel'].show();
            this.openedSignModel = type;
        }
      },
      hideSignature(){
        this.openedSignModel = null;
      },
      clearSignature(){
        this.$refs.signaturePad.clearSignature();
      },
      saveSignature(){
        const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
        
        if (isEmpty) {
  
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'Signature is required',
            showConfirmButton: false,
            timer: 1500
          })
  
        }else{
  
  
          if (this.openedSignModel == 'supervisor_signature') {
            
            this.supervisorSign.image = data;
            this.supervisorSign.name = 'siganture.png';
            this.supervisorSign.type = 'supervisor';
            this.supervisorSign.default = 'no';
  
            
          }else{
  
            this.supervisorSign.image = data;
            this.supervisorSign.name = 'signature.png';
            this.supervisorSign.type = 'supervisor';
            this.supervisorSign.default = 'no';
            this.status = 'approved';
            this.actualForm();
            
  
          }
  
          this.$refs['signatureModel'].hide();
        }
      },
      saveDefault(){
        
        if (this.openedSignModel == 'supervisor_signature') {
                      
          this.supervisorSign = {
            image : this.tempSign.image,
            name : 'signature.png',
            type :   'supervisor',
            default:'yes'
          }
          
        }else{
  
          this.supervisorSign = {
              image : this.tempSign.image,
              name : 'signature.png',
              default:'yes',
              type:'supervisor'
            }
            this.status = 'approved';
            this.actualForm();
        }
  
        this.$refs['signatureModel'].hide();
      },
      supervisorImageUpload(event){
        
        var input = event.target;
        var files = event.target.files
  
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.readAsDataURL(input.files[0]);
            var image = input.files[0];
            //console.log(image);
  
            if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
               
                Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    title: 'Please upload .png, .jpeg, .jpg images only',
                    showConfirmButton: false,
                    timer: 1500
                  })
  
                this.$refs.supervisorSInput.value=null;
  
            } else if (image.size > 2097152) {
  
                Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    title: 'Maximum 2 MB files allowed to be upload.',
                    showConfirmButton: false,
                    timer: 1500
                  })
                
                this.$refs.supervisorSInput.value=null;
                
  
            } else {
                reader.onload = (e) => {
  
                  this.supervisorSign.image = e.target.result;
                  this.supervisorSign.name = image.name;
                  this.supervisorSign.type = 'supervisor';
                  this.supervisorSign.default = 'no';
  
              }
            }
        }
        
  
      },
      removeSign(sign){
  
        this.supervisorSign.image = null;
        this.supervisorSign.name = '';
        this.supervisorSign.default = 'no';
      },
      formSubmit(e){
        e.preventDefault();
  
        if(this.status == 'draft'){
          this.actualForm();            
        } else {    
          this.decisionAlert('Are you sure want to submit this report ?')
          .then(result => {
            if (result.value) {
              this.actualForm();            
            }
          })
        }
        
      },
  
      actualForm(){
        return this.$store.dispatch(POST_API, {
           data:{
            inspection_date_time  : moment(this.checked_date_time, 'DD MMM YYYY HH:mm').format('YYYY-MM-DD HH:mm'),
             site                 : this.site,
             location_id          : this.location_id,
             inspected_by         : this.supervisor_name,
             checklists           : this.items,
             comment              : this.comment,
             ga                   : this.ga,
             supervisor_signature : this.supervisorSign,           
             no_photo_reporting   : this.no_photo_reporting,
             status               : this.status,
             id                   : this.id,
             form_id              : this.$route.params.id,
             approve_signature    : this.supervisorSign,
             approve_remark       : this.approve_remark,
             role                 : this.$store.getters.currentUser.role,
             approve_signature_time : this.approve_signature_time,
           },
           api: '/api/mark-complete-qm-qa'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data = this.$store.getters.getResults.data;
                
                localStorage.setItem('routeCFCheck','no');
  
                this.successAlert().then((result) => {
                    if (data != null) {
                        window.open(data, '_blank');
                    }
                    this.$router.push({ name:'qm-qa' })
                });
                
            }
        });
      },
  
      discardItem(id){
        var msg = 'Are you sure want to discard this record?';
  
        this.decisionAlert(msg)
        .then(result => {
            if (result.value) {
                return this.$store.dispatch(POST_API, {
                    data:{
                        id     : id,
                        status : 'deleted'
                    },
                    api : "/api/change-qm-qa-report-status",
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
  
                        this.showAlert();
                    } else {
                        localStorage.setItem('routeCFCheck','no');
                        this.$store.getters.getResults.message = 'Report Discard Successfully';
                        this.successAlert()
                        .then(() => {
                            this.flag = true;
  
                            if(this.$route.params.id){
                              this.$router.push({name:'qm-qa'})
                            }else{
                              this.$router.push({ name:'qm-qa' })
                            }
                        });
                    }
                });
            }
        })
        .catch(err => {
            console.log(err);
        })
      },
  
      checkdisabled(){
        if (this.ledit == true) {
          return false;
        }else if(this.disabledOptions == true){
          return true;
        }else{
          return false;
        }
      },
      goBack(){
        this.$router.push({ name: 'qm-qa' })
      }
  
    },
    mounted(){
      this.allSites().then( () => {
        if(this.$route.params.id){
          this.id = this.$route.params.id;
          this.getItemData();
        }
      });
      this.useDefault();
    }
  }
  </script>
  